import Vue from 'vue'

import qs from 'qs'
import VueJWT from 'vuejs-jwt'
import Vuex from 'vuex'

import { experimentTypes } from '@/enums/lims/experiment'
import router from '@/router/'
import authService from '@/services/authService'
import { authAsync, doAsync } from '@/stores/async-util'
import { createAsyncMutations } from '@/stores/mutation-types'
import types from '@/stores/types'
import { modules } from '@microbadevs/library'

Vue.use(Vuex)
Vue.use(VueJWT)

const state = {
  user: null,
  auth: null,
  isLoggedIn: authService?.isLoggedIn(),
  dashboard: null,
  userKits: null,
  userSurveys: null
}

const postLogin = createAsyncMutations(types.POST_LOGIN, state)
const postAuthenticationCode = createAsyncMutations(
  types.POST_AUTHENTICATION_CODE,
  state
)
const postRefresh = createAsyncMutations(types.POST_REFRESH, state)
const getUserByIdMutations = createAsyncMutations(types.GET_USER_BY_ID, state)
const getUserMutations = createAsyncMutations(types.GET_USER, state)
const getUsersMutations = createAsyncMutations(types.GET_USERS, state)
const postUserMutations = createAsyncMutations(types.POST_USER, state)
const putUserMutations = createAsyncMutations(types.PUT_USER, state)
const patchUserMutations = createAsyncMutations(types.PATCH_USER, state)
const getResetPassword = createAsyncMutations(types.GET_RESET_PASSWORD, state)
const getVoucherCodeByKitId = createAsyncMutations(
  types.GET_VOUCHER_CODE_BY_KIT_ID,
  state
)
const postResetPassword = createAsyncMutations(types.POST_RESET_PASSWORD, state)
const postTemporaryPassword = createAsyncMutations(
  types.POST_TEMPORARY_PASSWORD,
  state
)
const postEmailVerification = createAsyncMutations(
  types.POST_EMAIL_VERIFICATION,
  state
)
const getRoles = createAsyncMutations(types.GET_ROLES, state)
const getParentalConsent = createAsyncMutations(
  types.GET_PARENTAL_CONSENT,
  state
)
const postParentalConsent = createAsyncMutations(
  types.POST_PARENTAL_CONSENT,
  state
)
const postParentalConsentEmail = createAsyncMutations(
  types.POST_PARENTAL_CONSENT_EMAIL,
  state
)
const kitMutations = createAsyncMutations(types.GET_KITS, state)
const kitByUserIdMutations = createAsyncMutations(
  types.GET_KITS_BY_USER_ID,
  state
)
const kitByKitIdMutations = createAsyncMutations(types.GET_KIT_BY_KIT_ID, state)
const sentKitMutations = createAsyncMutations(types.GET_SENT_KITS, state)
const sentKitByUserIdMutations = createAsyncMutations(
  types.GET_SENT_KITS_BY_USER_ID,
  state
)
const kitActivation = createAsyncMutations(types.POST_KIT_ACTIVATION, state)
const kitShippingCancellation = createAsyncMutations(
  types.DELETE_KIT_SHIPPING,
  state
)
const shippingOrderMutations = createAsyncMutations(
  types.PUT_SHIPPING_ORDER,
  state
)
const dashboardMutations = createAsyncMutations(types.GET_DASHBOARD, state)
const orderMutations = createAsyncMutations(types.POST_ORDER, state)
const getSurveyMutations = createAsyncMutations(types.GET_SURVEY, state)
const searchMutations = createAsyncMutations(types.SEARCH, state)
const putSampleMutations = createAsyncMutations(types.PUT_SAMPLE, state)
const getKitSampleStatuses = createAsyncMutations(
  types.GET_KIT_SAMPLE_STATUSES,
  state
)
const getKitTimelineMutations = createAsyncMutations(
  types.GET_KIT_TIMELINE,
  state
)
const getSampleByIdentifier = createAsyncMutations(
  types.GET_SAMPLE_BY_IDENTIFIER,
  state
)
const getSampleByExternalReference = createAsyncMutations(
  types.GET_SAMPLE_BY_EXTERNAL_REFERENCE,
  state
)
const postResearchOrder = createAsyncMutations(types.POST_RESEARCH_ORDER, state)
const postResearchParticipant = createAsyncMutations(
  types.POST_RESEARCH_PARTICIPANT,
  state
)
const getPlates = createAsyncMutations(types.GET_PLATES, state)
const getExperiments = createAsyncMutations(types.GET_EXPERIMENTS, state)
const getRuns = createAsyncMutations(types.GET_RUNS, state)
const getRunById = createAsyncMutations(types.GET_LAB_RUN_BY_ID, state)
const getPlateById = createAsyncMutations(types.GET_PLATE_BY_ID, state)
const getWellsByPlateId = createAsyncMutations(
  types.GET_WELLS_BY_PLATE_ID,
  state
)
const getExperimentById = createAsyncMutations(
  types.GET_EXPERIMENT_BY_ID,
  state
)
const patchPlateById = createAsyncMutations(types.PATCH_PLATE_BY_ID, state)
const postRun = createAsyncMutations(types.POST_RUN, state)
const putUpdateRun = createAsyncMutations(types.PUT_UPDATE_RUN, state)
const getSamplesheet = createAsyncMutations(types.GET_SAMPLESHEET, state)
const getPlatePreparationCandidates = createAsyncMutations(
  types.GET_PLATE_PREPARATION_CANDIDATES,
  state
)
const postUploadSamplesheet = createAsyncMutations(
  types.POST_UPLOAD_SAMPLESHEET,
  state
)
const postValidateSamplesheet = createAsyncMutations(
  types.POST_VALIDATE_SAMPLESHEET,
  state
)
const postRunPreparation = createAsyncMutations(
  types.POST_RUN_PREPARATION,
  state
)
const postPlateToExperiment = createAsyncMutations(
  types.POST_PLATE_TO_EXPERIMENT,
  state
)
const postPlate = createAsyncMutations(types.POST_PLATE, state)
const deletePlateFromExperiment = createAsyncMutations(
  types.DELETE_PLATE_FROM_EXPERIMENT,
  state
)
const getSearchTypes = createAsyncMutations(
  types.GET_RESEARCH_PROJECT_SEARCH_TYPES,
  state
)
const getProjectByType = createAsyncMutations(
  types.GET_RESEARCH_PROJECT_BY_TYPE,
  state
)

const getResearchProjectParticipants = createAsyncMutations(
  types.GET_RESEARCH_PROJECT_PARTICIPANTS,
  state
)

const getResearchProjectKits = createAsyncMutations(
  types.GET_RESEARCH_PROJECT_KITS,
  state
)

const getSampleFile = createAsyncMutations(types.GET_SAMPLE_FILE, state)

const getSampleFiles = createAsyncMutations(types.GET_SAMPLE_FILESLIST, state)

const postSampleFile = createAsyncMutations(types.POST_SAMPLE_FILE, state)

const deleteSampleFile = createAsyncMutations(types.DELETE_SAMPLE_FILE, state)

const getSampleAliquots = createAsyncMutations(types.GET_SAMPLE_ALIQUOTS, state)

const postGenerateSampleAliquots = createAsyncMutations(
  types.POST_GENERATE_SAMPLE_ALIQUOTS,
  state
)

const getBrands = createAsyncMutations(types.GET_BRANDS, state)

const getReports = createAsyncMutations(types.GET_REPORTS, state)
const getReportById = createAsyncMutations(types.GET_REPORT_BY_ID, state)
const getReportsByReferralIds = createAsyncMutations(types.GET_REPORTS_BY_REFERRAL_IDS, state)
const updateReport = createAsyncMutations(types.PATCH_REPORT_BY_ID, state)
const getReferralByKitId = createAsyncMutations(
  types.GET_REFERRAL_BY_KIT_ID,
  state
)
const getReferralsByPatientName = createAsyncMutations(
  types.GET_REFERRALS_BY_PATIENT_NAME,
  state
)
const cancelReferralById = createAsyncMutations(
  types.CANCEL_REFERRAL_BY_ID,
  state
)
const sendReferralNotificationById = createAsyncMutations(
  types.SEND_REFERRAL_NOTIFICATION_BY_ID,
  state
)
const getReferralsByIds = createAsyncMutations(
  types.GET_REFERRALS_BY_REFERRAL_IDS,
  state
)
const getReferralsByPractitionerEmail = createAsyncMutations(
  types.GET_REFERRALS_BY_PRACTITIONER_EMAIL,
  state
)
const getPatientById = createAsyncMutations(types.GET_PATIENT_BY_ID, state)

const replaceSample = createAsyncMutations(types.REPLACE_SAMPLE, state)

const authMutations = {
  LOGIN_SUCCESS(state) {
    state.isLoggedIn = true
    state.pending = false
    router.push('profile')
  },
  LOGOUT(state) {
    state.isLoggedIn = false
    router.push('/')
  }
}

const buildUrlWithPathVariables = (base, params) => {
  const query = Object.keys(params)
  .filter(key => params[key] != null && params[key] !== '')
  .map(key => `${key}=${params[key]}`)
  .join('&')

  return `${base}?${query}`
}

const actions = {
  login: async (context, payload) => {
    try {
      const response = await authAsync(context, {
        url: 'oauth/token',
        mutationType: types.POST_LOGIN,
        method: 'POST',
        data: qs.stringify({
          grant_type: 'password',
          username: payload.email,
          password: payload.password,
          client_id: 'staff-portal-web-app'
        })
      })
      authService.initialize(
        response?.data?.access_token,
        response?.data?.refresh_token
      )
      let routeName = 'Dashboard'
      let lastVisited = null
      const userResponse = await authService.isUserEnabling2FA()

      if (userResponse) {
        routeName = 'two-factor-authentication-set-up'
      } else {
        lastVisited = localStorage.getItem('last_visited')
      }

      router.push(lastVisited ?? { name: routeName })
    } catch (error) {
      const mfaToken = error?.response?.data?.mfa_token
      const is2FARequired = authService.is2FARequired(error)
      if (is2FARequired) {
        router.push({ name: 'two-factor-authentication', params: { mfaToken } })
      }
      throw error
    }
  },

  verify2FACode: async (context, payload) => {
    const response = await authAsync(context, {
      url: 'oauth/token',
      mutationType: types.POST_AUTHENTICATION_CODE,
      method: 'POST',
      data: qs.stringify({
        grant_type: 'mfa_verification',
        mfa_token: payload?.mfa_token,
        mfa_verification_code: payload?.mfa_verification_code
      })
    })
    authService.initialize(
      response?.data?.access_token,
      response?.data?.refresh_token
    )

    const lastVisited = localStorage.getItem('last_visited')
    router
      .push({ path: lastVisited ? lastVisited : '/dashboard' })
      .catch(() => {})
  },

  refreshAccessToken: (context, payload) => {
    return authAsync(context, {
      url: 'oauth/token',
      mutationType: types.POST_REFRESH,
      method: 'POST',
      data: qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: payload.refreshToken
      })
    })
  },

  logout: (context) => {
    authService.logout()
    window.location.reload(false)
    context.commit('LOGOUT')
  },

  getDashboard: (context) => {
    authService.getUserID()
    return doAsync(context, {
      url: 'user/' + authService.getUserID() + '/dashboard',
      mutationType: types.GET_DASHBOARD
    })
  },

  search: (context, payload) => {
    return doAsync(context, {
      url: 'search?keyword=' + payload,
      mutationType: types.SEARCH
    })
  },

  getUser: (context) => {
    return doAsync(context, {
      url: 'user/' + authService.getUserID(),
      mutationType: types.GET_USER
    })
  },

  getUserById: (context, userId) => {
    return doAsync(context, {
      url: 'user/' + userId,
      mutationType: types.GET_USER_BY_ID
    })
  },

  getUsers: (context) => {
    return doAsync(context, {
      url: 'users',
      mutationType: types.GET_USERS
    })
  },

  postUser: (context, payload) => {
    return doAsync(context, {
      url: '/public/registration/',
      method: 'POST',
      mutationType: types.POST_USER,
      data: payload
    })
  },

  putUser: (context, data) => {
    const user = data
    if (user.password === null || user.newPassword === null) {
      delete user.password
      delete user.newPassword
    }
    return doAsync(context, {
      url: 'user/' + authService.getUserID(),
      method: 'PUT',
      mutationType: types.PUT_USER,
      data: user
    })
  },

  patchUser: (context, data) => {
    const user = data
    return doAsync(context, {
      url: 'user/' + user.id,
      method: 'PATCH',
      mutationType: types.PATCH_USER,
      data: user
    })
  },

  getPasswordReset: (context, email) => {
    return doAsync(context, {
      url: '/public/reset-password?username=' + email,
      mutationType: types.GET_RESET_PASSWORD
    })
  },

  postPasswordReset: (context, payload) => {
    return doAsync(context, {
      url: '/public/reset-password',
      method: 'POST',
      mutationType: types.POST_RESET_PASSWORD,
      data: payload
    })
  },

  postPasswordTemporary: (context, payload) => {
    return doAsync(context, {
      url: '/temporary-password',
      method: 'POST',
      mutationType: types.POST_TEMPORARY_PASSWORD,
      data: payload
    })
  },

  postEmailVerification: (context, payload) => {
    return doAsync(context, {
      url: '/public/email-verification/' + payload,
      method: 'POST',
      mutationType: types.POST_EMAIL_VERIFICATION
    })
  },

  getRoles: (context) => {
    return doAsync(context, {
      url: '/admin/roles',
      method: 'GET',
      mutationType: types.GET_ROLES
    })
  },

  getParentalConsentForm: (context, userId) => {
    return doAsync(context, {
      url: 'user/' + userId + '/parental-consent',
      mutationType: types.POST_PARENTAL_CONSENT,
      responseType: 'arraybuffer'
    })
  },

  postParentalConsentForm: (context, payload) => {
    return doAsync(context, {
      url: 'user/' + payload.userId + '/parental-consent',
      method: 'POST',
      data: payload,
      mutationType: types.POST_PARENTAL_CONSENT
    })
  },

  postParentalConsentEmail: (context, payload) => {
    return doAsync(context, {
      url:
        'user/' +
        authService.getUserID() +
        '/parental-consent?email=' +
        payload,
      method: 'POST',
      mutationType: types.POST_PARENTAL_CONSENT_EMAIL
    })
  },

  postSampleUndispatch: (context, sampleId) => {
    return doAsync(context, {
      url: `sample/undispatch/${sampleId}`,
      method: 'POST'
    })
  },

  postSampleDispatch: (context, payload) => {
    let url = `sample/dispatch/${payload.sampleIdentifier}`
    if (payload.shippingOrderId) {
      url = `${url}?shipping-order-id=${payload.shippingOrderId}`
    } else if (payload.researchProjectId) {
      /*
        This end point is specific for CLIENT MANAGED RESEARCH sample only
        which needs to be assigned to a research project and will return
        only one outgoing shipment becase it is managed by client.
      */
      url = `${url}?research-project-id=${payload.researchProjectId}`
    }
    return doAsync(context, {
      url: url,
      method: 'POST'
    })
  },

  postValidateShippingOrderWithDispatch: (context, payload) => {
    return doAsync(context, {
      url: `/dispatch/shipping-order/${payload.shippingOrderId}/validation`,
      method: 'POST',
      data: payload.sampleIdentifiers
    })
  },

  postShippingOrderDispatch: (context, payload) => {
    return doAsync(context, {
      url: `/dispatch/shipping-order/${payload.shippingOrderId}`,
      method: 'POST',
      data: payload.sampleIdentifiers
    })
  },

  postUnDispatch: (context, payload) => {
    const url = `un-dispatch/shipping-order/${payload.id}`
    return doAsync(context, {
      url: url,
      method: 'POST'
    })
  },

  postKitBundling: (context, payload) => {
    const url = `/sample/bundle`
    return doAsync(context, { url, method: 'POST', data: payload })
  },

  postShipmentOrder: (context, payload) => {
    const url = `shipment/order`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  putShippingOrder: (context, payload) => {
    return doAsync(context, {
      url: `shipping-order/${payload.id}`,
      method: 'PUT',
      mutationType: types.PUT_SHIPPING_ORDER,
      data: payload
    })
  },

  postTubeSupplierBatch: (context, payload) => {
    const url = `tube-supplier-batch/?start-sample-identifier=${payload.sampleIdentifier}&tube-number=${payload.noOfTubes}`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  getShipmentOrderSummary: (context, payload) => {
    return doAsync(context, {
      url: `shipment/order/${payload}/summary`,
      method: 'GET'
    })
  },

  getDigitalCertificate: (context, payload) => {
    return doAsync(context, {
      url: `public/digital-certificate`,
      method: 'GET'
    })
  },

  getSignMessage: (context, payload) => {
    return doAsync(context, {
      url: `public/sign-message`,
      method: 'GET'
    })
  },
  postRequestLabel: (context, payload) => {
    return doAsync(context, {
      url: `shipment/${payload.id}/label`,
      method: 'POST'
    })
  },

  getLabel: (context, payload) => {
    return doAsync(context, {
      url: `shipment/${payload.id}/label`,
      method: 'GET'
    })
  },

  getTubeSupplierBatches: (context, payload) => {
    let url = 'tube-supplier-batches'
    url +=
      payload && payload.dispatched !== null
        ? `?dispatched=${payload.dispatched}`
        : ''
    return doAsync(context, {
      url: url,
      method: 'GET'
    })
  },

  getBatchSamples: (context, payload) => {
    const url = `tube-supplier-batch/${payload.batchId}`
    return doAsync(context, {
      url: url,
      method: 'GET'
    })
  },

  putUpdateTubeSupplierBatch: (context, payload) => {
    return doAsync(context, {
      url: `tube-supplier-batch/${payload.id}`,
      data: payload,
      method: 'PUT'
    })
  },

  getShippingOrders: (context, payload) => {
    return doAsync(context, {
      url: `shipping-orders/?pending=${payload.pending}`,
      method: 'GET'
    })
  },

  getUserKits: (context) => {
    return doAsync(context, {
      url: 'user/' + authService.getUserID() + '/kits',
      mutationType: types.GET_KITS
    })
  },

  getKitsByUserId: (context, payload) => {
    return doAsync(context, {
      url: `user/${payload}/kits`,
      mutationType: types.GET_KITS_BY_USER_ID
    })
  },
  getKitInfoByKitId: (context, kitId) => {
    return doAsync(context, {
      url: `kit/${kitId}`,
      mutationType: types.GET_KIT_BY_KIT_ID
    })
  },
  getKitByKitId: (context, payload) => {
    return doAsync(context, {
      url: `kit/receipt/${payload}`, // TODO
      mutationType: types.GET_KIT_BY_KIT_ID
    })
  },
  getVoucherCodeByKitId: (context, payload) => {
    return doAsync(context, {
      url: `kit/${payload}/voucher-code`,
      mutationType: types.GET_VOUCHER_CODE_BY_KIT_ID
    })
  },

  getSentKits: (context) => {
    return doAsync(context, {
      url: 'user/' + authService.getUserID() + '/kit-send-requests',
      mutationType: types.GET_SENT_KITS
    })
  },

  getSentKitsByUserId: (context, payload) => {
    return doAsync(context, {
      url: `user/${payload}/kits?sent=true`,
      mutationType: types.GET_SENT_KITS_BY_USER_ID
    })
  },

  updateKit: (context, payload) => {
    return doAsync(context, {
      url: `kit/${payload.kitId}`,
      method: 'PUT',
      data: {
        kitId: payload.kitId,
        reportReceived: payload.reportReceived
      }
    })
  },

  putKit: (context, data) => {
    return doAsync(context, {
      url: 'kit/' + data.id,
      method: 'PUT',
      data: data
    })
  },

  activateKit: (context, payload) => {
    return doAsync(context, {
      url: 'kit/' + payload.kitId + '/shipping',
      method: 'POST',
      data: payload
    })
  },

  cancelKitShipping: (context, kitId) => {
    return doAsync(context, {
      url: 'kit/' + kitId + '/shipping',
      method: 'DELETE',
      mutationType: types.DELETE_KIT_SHIPPING
    })
  },

  markAsPriority: (context, payload) => {
    return doAsync(context, {
      url: `kit/${payload.kitId}`,
      method: 'PUT',
      data: {
        kitId: payload.kitId,
        priority: payload.priority
      }
    })
  },

  checkVoucher: (context, payload) => {
    return doAsync(context, {
      url: 'kit/voucher-code/' + payload.voucher,
      method: 'GET'
    })
  },

  redeemKit: (context, payload) => {
    return doAsync(context, {
      url:
        'user/' +
        authService.getUserID() +
        '/kit?redeem-voucher-code=' +
        payload.voucher +
        '&activate=' +
        payload.activate,
      method: 'POST',
      data: payload
    })
  },

  // TODO: POST /kit/{id}/voucher-code?to={email}
  sendKit: (context, payload) => {
    return doAsync(context, {
      url: 'kit/' + payload.kitId + '/voucher-code?to=' + payload.email,
      method: 'POST',
      data: payload
    })
  },

  transferKit: (context, payload) => {
    return doAsync(context, {
      url: 'kit/' + payload.kitId + '/to/' + payload.toUserId,
      method: 'POST',
      data: payload
    })
  },

  getVoucher: (context, payload) => {
    return doAsync(context, {
      url: `kit/${payload.kitId}/voucher-code`
    })
  },

  getUserSurveys: (context) => {
    return doAsync(context, {
      url: 'user/' + authService.getUserID() + '/surveys',
      mutationType: types.GET_SURVEY
    })
  },

  getSurveyAnswers: (context, data) => {
    return doAsync(context, {
      url: 'kit/' + data.kitId + '/survey/' + data.type
    })
  },

  getSurveyQuestions: (context, type) => {
    return doAsync(context, {
      url: 'survey/' + type
    })
  },

  putSurveyQuestion: (context, data) => {
    return doAsync(context, {
      url: 'kit/' + data.kitId + '/survey',
      method: 'PUT',
      data: data.result
    })
  },

  postOrder: (context, payload) => {
    return doAsync(context, {
      url: 'order/',
      method: 'POST',
      mutationType: types.POST_ORDER,
      data: payload
    }).then((response) => {
      if (response.data) {
        return response
      }
    })
  },

  postCoBiomeOrder: async (context, payload) => {
    return await doAsync(context, {
      url: `redispatch/${payload.sampleIdentifier}`,
      method: 'POST',
      mutationType: types.POST_ORDER,
      data: payload
    })
  },

  getSamples: (context, payload) => {
    return doAsync(context, {
      url: 'samples?status=' + payload.status,
      method: 'GET'
    })
  },

  getControlSamples: (context, payload) => {
    return doAsync(context, {
      url: 'control-samples?next-available=1&count=3',
      method: 'GET'
    })
  },

  lookupRelated: (context, payload) => {
    return doAsync(context, {
      url: 'sample/identifier/' + payload.id + '/related',
      method: 'GET'
    })
  },

  postResearchOrder: (context, payload) => {
    return doAsync(context, {
      url: `researcher/${payload.researcherId}/order`,
      method: 'POST',
      mutationType: types.POST_RESEARCH_ORDER,
      data: payload
    })
  },

  postResearchParticipant: (context, payload) => {
    return doAsync(context, {
      url: `research-project/${payload.researchProjectId}/participants`,
      method: 'POST',
      mutationType: types.POST_RESEARCH_PARTICIPANT,
      data: payload.requestBody
    })
  },

  putSample: (context, payload) => {
    return doAsync(context, {
      url: 'sample/' + payload.id,
      method: 'PUT',
      mutationType: types.PUT_SAMPLE,
      data: payload
    })
  },

  putSamplesMultiple: (context, payload) => {
    return doAsync(context, {
      url: 'samples',
      method: 'PUT',
      data: payload
    })
  },

  putControlsMultiple: (context, payload) => {
    return doAsync(context, {
      url: 'control-samples',
      method: 'POST',
      data: payload
    })
  },

  postBatch: (context, payload) => {
    let url = `sample/batch?count=${payload.amount}&product-id=${payload.productId}`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload.sampleMetaData
    })
  },

  getControlSampleTypes: (context, payload) => {
    const url = 'lab/control-sample-types'
    return doAsync(context, {
      url,
      method: 'GET'
    })
  },

  getBatch: (context, payload) => {
    const url = `sample/batch/${payload.id}`
    return doAsync(context, {
      url,
      method: 'GET'
    })
  },

  getListOfBatches: (context, payload) => {
    const url = 'sample/batches'
    return doAsync(context, {
      url,
      method: 'GET'
    })
  },

  getSubscriptions: (context, payload) => {
    let url = 'subscriptions'
    url += payload && payload.due ? `?due=${payload.due}` : ''
    return doAsync(context, {
      url: url,
      method: 'GET'
    })
  },

  getAllSubscriptions: (context, payload) => {
    return doAsync(context, {
      url: `user/${payload.id}/subscriptions`,
      method: 'GET'
    })
  },

  postSubscription: (context, payload) => {
    let url = 'subscription'
    url = payload.id ? `${url}/${payload.id}` : url
    return doAsync(context, {
      url: url,
      method: 'POST'
    })
  },

  // Informed Professionals
  getInformedProfessional: (context, payload) => {
    return doAsync(context, {
      url: 'microba-informed-professionals',
      method: 'GET'
    })
  },
  postInformedProfessional: (context, payload) => {
    return doAsync(context, {
      url: 'microba-informed-professional',
      method: 'POST',
      data: payload
    })
  },
  putInformedProfessional: (context, payload) => {
    return doAsync(context, {
      url: 'microba-informed-professional/' + payload.id,
      method: 'PUT',
      data: payload
    })
  },
  getHealthProfessionals: (context, payload) => {
    return doAsync(context, {
      url: 'health-care-practitioners',
      method: 'GET'
    })
  },
  getHealthProfessional: (context, payload) => {
    return doAsync(context, {
      url: 'health-care-practitioner/' + payload.id,
      method: 'GET'
    })
  },
  putHealthProfessional: (context, payload) => {
    return doAsync(context, {
      url: 'health-care-practitioner/' + payload.id,
      method: 'PUT',
      data: payload
    })
  },
  patchHealthProfessional: (context, payload) => {
    return doAsync(context, {
      url: 'health-care-practitioner/' + payload.id,
      method: 'PATCH',
      data: payload
    })
  },
  getSampleValidate: (context, sampleIdentifier) => {
    return doAsync(context, {
      url: '/sample/identifier/' + sampleIdentifier
    })
  },
  getDecodedIdentifier: (context, activationCode) => {
    return doAsync(context, {
      url: `/dispatch/sample/${activationCode}/decode`
    })
  },

  // Research functions
  getResearchers: (context, payload) => {
    return doAsync(context, {
      url: 'researchers',
      method: 'GET'
    })
  },
  postResearcher: (context, payload) => {
    return doAsync(context, {
      url: 'researcher',
      method: 'POST',
      data: payload
    })
  },
  putResearcher: (context, payload) => {
    return doAsync(context, {
      url: 'researcher/' + payload.id,
      method: 'PUT',
      data: payload
    })
  },

  getProjects: (context, payload) => {
    return doAsync(context, {
      url: 'researcher/' + payload.id + '/projects',
      method: 'GET'
    })
  },

  getSitesByProjectId: (context, payload) => {
    const url = `sites?research-project-id=${payload.id}`
    return doAsync(context, {
      url,
      method: 'GET'
    })
  },

  postResearchProject: (context, payload) => {
    return doAsync(context, {
      url: 'researcher/' + payload.researcherId + '/project',
      method: 'POST',
      data: payload
    })
  },

  putResearchProject: (context, payload) => {
    return doAsync(context, {
      url: 'research-project/' + payload.id,
      method: 'PUT',
      data: payload
    })
  },

  getProject: (context, payload) => {
    return doAsync(context, {
      url: 'research-project/' + payload.id,
      method: 'GET'
    })
  },

  deleteProject: (context, payload) => {
    return doAsync(context, {
      url: 'research-project/' + payload.id,
      method: 'DELETE'
    })
  },

  postOrbitMShippingOrder: (context, payload) => {
    const url = `/site/${payload.id}/shipping-order`
    return doAsync(context, {
      url,
      method: 'POST',
      data: payload
    })
  },

  getKitSampleStatuses: (context) => {
    return doAsync(context, {
      url: `kit-sample-statuses`,
      method: 'GET',
      mutationType: types.GET_KIT_SAMPLE_STATUSES
    })
  },

  getKitTimeline: (context, payload) => {
    return doAsync(context, {
      url: `kit/${payload}/timeline`,
      mutationType: types.GET_KIT_TIMELINE
    })
  },

  generatePromoCode: (context, payload) => {
    return doAsync(context, {
      url: `promo-code`,
      method: 'POST',
      data: payload
    })
  },

  getAllPromoCodes: (context) => {
    return doAsync(context, {
      url: `promo-codes`
    })
  },

  invalidatePromoCode: (context, payload) => {
    return doAsync(context, {
      url: `promo-code/${payload.code}?product-type=${payload.productType}`,
      method: 'DELETE'
    })
  },

  checkReferralCode: (context, payload) => {
    return doAsync(context, {
      url: 'public/microba-informed-professional/' + payload,
      method: 'GET'
    })
  },

  postCopyQuestionnaires: (context, payload) => {
    return doAsync(context, {
      url: `kit/${payload.sourceKitId}/survey/${payload.surveyType}/toKit/${payload.destinationKitId}`,
      method: 'POST'
    })
  },

  getSampleByIdentifier: (context, payload) => {
    return doAsync(context, {
      url: `sample/identifier/${payload.sampleIdentifier}`,
      mutationType: types.GET_SAMPLE_BY_IDENTIFIER
    })
  },

  getSampleByExternalReference: (context, payload) => {
    return doAsync(context, {
      url: `sample/external-reference/${payload.externalReference}`,
      mutationType: types.GET_SAMPLE_BY_EXTERNAL_REFERENCE
    })
  },

  putSampleByIdentifier: (context, payload) => {
    return doAsync(context, {
      url: `sample/identifier/${payload.sampleIdentifier}`,
      method: 'PUT',
      data: payload
    })
  },

  postEmailCustomerReview: (context, payload) => {
    return doAsync(context, {
      url: `admin/sqs/${process.env.VUE_APP_NOTIFICATION_QUEUE}`,
      method: 'POST',
      data: {
        to: payload.to,
        templateId: process.env.VUE_APP_CUSTOMER_REVIEW_TEMPLATE_ID
      }
    })
  },

  getSampleWithMetaInformation: (context, payload) => {
    return doAsync(context, {
      url: `internal/samples?status=${payload.status}`,
      method: 'GET'
    })
  },
  getStaffList: (context) => {
    return doAsync(context, {
      url: 'admin/staff',
      method: 'GET'
    })
  },
  getSampleMetaData: (context, payload) => {
    const url = `internal/sample/identifier/${payload.sampleIdentifier}/metadata`
    return doAsync(context, {
      url: url,
      method: 'GET'
    })
  },

  postRun: (context, payload) => {
    const url = `lab/run`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  putUpdateRun: (context, payload) => {
    const url = `lab/run/${payload.id}`
    return doAsync(context, {
      url: url,
      method: 'PUT',
      mutationType: types.PUT_UPDATE_RUN,
      data: payload
    })
  },

  getSamplesheet: (context, payload) => {
    const url = `lab/run/${payload.runId}/sample-sheet?format=${payload.format}`
    return doAsync(context, {
      url: url,
      method: 'GET'
    })
  },

  getPlatePreparationCandidates: (context, payload) => {
    const url = `lab/run/plate-prepation-candidates`
    return doAsync(context, {
      url: url,
      method: 'GET'
    })
  },

  getPlateSampleCandidates: (context, payload) => {
    const baseUrl = 'lab/run/plate-sample-candidates'
    const url = buildUrlWithPathVariables(baseUrl, payload)

    return doAsync(context, {
      url: url,
      method: 'GET'
    })
  },

  postUploadSamplesheet: (context, payload) => {
    const url = `lab/run/${payload.runId}/sample-sheet?remove-sample=${payload.removeSample}`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  postValidateSamplesheet: (context, payload) => {
    const url = `lab/run/${payload.runId}/sample-sheet/validation?remove-sample=${payload.removeSample}`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  postExperiment: (context, payload) => {
    const url = `lab/experiment`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  putExperiment: (context, payload) => {
    return doAsync(context, {
      url: `lab/experiment/${payload.id}`,
      method: 'PUT',
      data: payload
    })
  },

  postPlateToExperiment: (context, payload) => {
    const url = `lab/experiment/${payload.experimentId}/plate`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  postPlate: (context, payload) => {
    const url = 'lab/plate'
    return doAsync(context, {
      url,
      method: 'POST',
      data: payload
    })
  },

  deletePlateFromExperiment: (context, payload) => {
    const url = `lab/experiment/${payload.experimentId}/plate/${payload.id}`
    return doAsync(context, {
      url: url,
      method: 'DELETE',
      data: payload
    })
  },

  getPlates: (context, payload) => {
    let baseUrl = `lab/plates`
    const url = buildUrlWithPathVariables(baseUrl, payload)
    return doAsync(context, { url: url, method: 'GET' })
  },

  getExperiments: (context, payload) => {
    let url = `lab/experiments`
    const experimentType =
      payload?.experimentType || experimentTypes.PLATE_PREPARATION
    url = `${url}?type=${experimentType}`
    url += payload?.open !== undefined ? `&open=${payload.open}` : ''
    return doAsync(context, { url: url, method: 'GET' })
  },

  getExperimentById: (context, payload) => {
    const url = `lab/experiment/${payload.experimentId}`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_EXPERIMENT_BY_ID
    })
  },

  getPlateById: (context, payload) => {
    const url = `lab/plate/${payload.plateId}`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_PLATE_BY_ID
    })
  },

  getWellsByPlateId: (context, payload) => {
    const url = `lab/plate/${payload.plateId}/wells`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_WELLS_BY_PLATE_ID
    })
  },

  patchPlateById: (context, payload) => {
    const wells = payload.wells
    return doAsync(context, {
      url: `/lab/plate/${payload.id}/wells`,
      method: 'PATCH',
      mutationType: types.PATCH_PLATE_BY_ID,
      data: wells
    })
  },

  getRuns: (context, payload) => {
    const url = `lab/runs`
    return doAsync(context, {
      url: url,
      method: 'GET',
      mutationType: types.GET_RUNS
    })
  },

  getRunById: (context, payload) => {
    const url = `lab/run/${payload.id}`
    return doAsync(context, {
      url: url,
      method: 'GET',
      mutationType: types.GET_LAB_RUN_BY_ID
    })
  },

  postRunPreparation: (context, payload) => {
    const url = `lab/run/${payload.runId}/preparation`
    return doAsync(context, {
      url: url,
      method: 'POST',
      data: payload
    })
  },

  getSearchTypes: (context) => {
    const url = `/research-project-search-types`
    return doAsync(context, {
      url: url,
      method: 'GET',
      mutationType: types.GET_RESEARCH_PROJECT_SEARCH_TYPES
    })
  },

  getProjectByType: (context, payload) => {
    const url = `/research-projects?type=${payload.type}&keyword=${payload.term}`
    return doAsync(context, {
      url: url,
      method: 'GET',
      mutationType: types.GET_RESEARCH_PROJECT_BY_TYPE
    })
  },

  getResearchProjectParticipants: (context, payload) => {
    const url = `/research-project/${payload.id}/participants`
    return doAsync(context, {
      url: url,
      method: 'GET',
      mutationType: types.GET_RESEARCH_PROJECT_PARTICIPANTS
    })
  },

  getResearchProjectKits: (context, payload) => {
    const url = `/research-project/${payload.id}/kits`
    return doAsync(context, {
      url: url,
      method: 'GET',
      mutationType: types.GET_RESEARCH_PROJECT_SAMPLES
    })
  },

  getSampleFile: (context, { sampleIdentifier, fileId }) => {
    const url = `/sample/${sampleIdentifier}/file/${fileId}`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_SAMPLE_FILE
    })
  },

  getSampleFiles: (context, sampleIdentifier) => {
    const url = `/sample/${sampleIdentifier}/files`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_SAMPLE_FILESLIST
    })
  },

  postSampleFile: (context, { sampleIdentifier, formData }) => {
    const url = `/sample/${sampleIdentifier}/file`
    return doAsync(context, {
      url,
      method: 'POST',
      data: formData,
      mutationType: types.POST_SAMPLE_FILE
    })
  },

  deleteSampleFile: (context, { sampleIdentifier, fileId }) => {
    const url = `/sample/${sampleIdentifier}/file/${fileId}`
    return doAsync(context, {
      url,
      method: 'DELETE',
      mutationType: types.DELETE_SAMPLE_FILE
    })
  },

  getSampleAliquots: (context, sampleIdentifier) => {
    const url = `/sample/${sampleIdentifier}/aliquots`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_SAMPLE_ALIQUOTS
    })
  },

  postGenerateSampleAliquots: (context, { sampleIdentifier, params }) => {
    const url = `/sample/${sampleIdentifier}/aliquots`
    return doAsync(context, {
      url: url,
      method: 'POST',
      mutationType: types.POST_GENERATE_SAMPLE_ALIQUOTS,
      data: params
    })
  },

  getBrands: (context) => {
    const url = `${process.env.VUE_APP_INTERNAL_API}brands`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_BRANDS
    })
  },

  getReports: (context, payload) => {
    let url = `${process.env.VUE_APP_REPORT_SERVICE_URL}/reports`
    const noOfReports = payload?.noOfReports ?? 100
    const page = payload?.page ?? 0
    const sortBy = payload?.sortBy ?? 'id'
    const sortDirection = payload?.sortDirection ?? 'desc'
    const keyword = payload?.keyword ?? ''

    url += '?' + [
      `size=${noOfReports}`,
      `page=${page}`,
      `sort-by=${sortBy}`,
      `sort-direction=${sortDirection}`,
      `search=${keyword}`
    ].join('&')

    if (payload?.status) {
      url += `&statuses=${payload.status.toString()}`
    }
    if (payload?.summaryStatuses) {
      url += `&summary-statuses=${payload.summaryStatuses?.toString()}`
    }
    if (payload?.product) {
      url += `&product=${payload.product}`
    }
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_REPORTS
    })
  },
  getReportById: (context, payload) => {
    const url = `${process.env.VUE_APP_REPORT_SERVICE_URL}/report/${payload.id}`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_REPORT_BY_ID
    })
  },
  getReportsByReferralIds: (context, payload) => {
    let url = `${process.env.VUE_APP_REPORT_SERVICE_URL}/reports`

    url += '?' + [
      `size=${payload?.noOfReports || 100}`,
      `page=${payload?.page || 0}`,
      `sort-by=${payload?.sortBy ?? 'id'}`,
      `sort-direction=${payload?.sortDirection || 'desc'}`,
      ].join('&')

    if (payload?.status) {
      url += `&statuses=${payload.status.toString()}`
    }
    if (payload?.summaryStatuses) {
      url += `&summary-statuses=${payload.summaryStatuses?.toString()}`
    }

    return doAsync(context, {
      url,
      method: 'POST',
      mutationType: types.GET_REPORTS_BY_REFERRAL_IDS,
      data: payload.request
    })
  },
  updateReport: (context, payload) => {
    const url = `${process.env.VUE_APP_REPORT_SERVICE_URL}/report/${payload.id}`

    return doAsync(context, {
      url,
      method: 'PATCH',
      mutationType: types.PATCH_REPORT_BY_ID,
      data: payload
    })
  },
  getReferralByKitId: (context, payload) => {
    const url = `${process.env.VUE_APP_HCP_JOURNEY_URL}/referral/?kit-id=${payload.kitId}`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_REFERRAL_BY_KIT_ID
    })
  },
  getReferralsByPatientName: (context, payload) => {
    const url = `${
      process.env.VUE_APP_HCP_JOURNEY_URL
    }/referrals/search?first-name=${payload.patientFname || ''}&last-name=${
      payload.patientLname || ''
    }&keyword=${payload.keyword || ''}`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_REFERRALS_BY_PATIENT_NAME
    })
  },
  getReferralsByIds: (context, payload) => {
    const pageSize = payload?.size ?? 100
    const page = payload?.page ?? 0
    const url = `${process.env.VUE_APP_HCP_JOURNEY_URL}/referrals?page=${page}&size=${pageSize}`
    return doAsync(context, {
      url,
      method: 'POST',
      mutationType: types.GET_REFERRALS_BY_REFERRAL_IDS,
      data: payload.request
    })
  },
  getReferralsByPractitionerEmail: (context, payload) => {
    const url = `${process.env.VUE_APP_HCP_JOURNEY_URL
      }/referrals?practitioner-email=${payload.practitionerEmail}&page=${payload.page || 0
      }&size=${payload.size || 100}`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_REFERRALS_BY_PRACTITIONER_EMAIL
    })
  },
  cancelReferralById: (context, payload) => {
    const url = `${process.env.VUE_APP_HCP_JOURNEY_URL}/referral/id(${payload.referralId})`
    return doAsync(context, {
      url,
      method: 'DELETE',
      mutationType: types.CANCEL_REFERRAL_BY_ID
    })
  },
  sendReferralNotificationById: (context, payload) => {
    const url = `${process.env.VUE_APP_HCP_JOURNEY_URL}/referral/id(${payload.referralId})/notification`
    return doAsync(context, {
      url,
      method: 'POST',
      mutationType: types.SEND_REFERRAL_NOTIFICATION_BY_ID
    })
  },
  postLogs: (context, payload) => {
    return doAsync(context, {
      url: `admin/log`,
      method: 'POST',
      data: {
        title: payload.title,
        message: payload.message
      }
    })
  },
  replaceSample: (context, { sampleIdentifier, data }) => {
    const url = `${process.env.VUE_APP_API_ROOT}/redispatch/${sampleIdentifier}`
    return doAsync(context, {
      url,
      method: 'POST',
      mutationType: types.REPLACE_SAMPLE,
      data
    })
  },
  getPatientById: (context, id) => {
    const url = `${process.env.VUE_APP_HCP_JOURNEY_URL}/patient/id(${id})`
    return doAsync(context, {
      url,
      method: 'GET',
      mutationType: types.GET_PATIENT_BY_ID
    })
  },
  deleteShipmentsByShippingOrder: (context, orderId) => {
    return doAsync(context, {
      url: `shipment/shipping-order/${orderId}`,
      method: 'DELETE'
    })
  },
  deleteShipmentByShipmentId: (context, shipmentId) => {
    return doAsync(context, {
      url: `shipment/${shipmentId}`,
      method: 'DELETE'
    })
  },
  getDraftSummary: (context, reportId) => {
    const url = `${process.env.VUE_APP_REPORT_SERVICE_URL}/report/${reportId}/expert-summary-draft`
    return doAsync(context, {
      url,
      method: 'GET'
    })
  }
}

export default new Vuex.Store({
  state,
  modules: {
    maintenance: modules.maintenance
  },
  mutations: {
    ...postLogin,
    ...postAuthenticationCode,
    ...postRefresh,
    ...putUserMutations,
    ...postUserMutations,
    ...patchUserMutations,
    ...getUserMutations,
    ...getUserByIdMutations,
    ...getUsersMutations,
    ...getSearchTypes,
    ...getProjectByType,
    ...getResetPassword,
    ...postResetPassword,
    ...postTemporaryPassword,
    ...postEmailVerification,
    ...getRoles,
    ...getParentalConsent,
    ...postParentalConsent,
    ...postParentalConsentEmail,
    ...kitMutations,
    ...sentKitMutations,
    ...sentKitByUserIdMutations,
    ...kitActivation,
    ...kitShippingCancellation,
    ...shippingOrderMutations,
    ...dashboardMutations,
    ...orderMutations,
    ...getSurveyMutations,
    ...authMutations,
    ...searchMutations,
    ...kitByUserIdMutations,
    ...kitByKitIdMutations,
    ...putSampleMutations,
    ...getKitSampleStatuses,
    ...getKitTimelineMutations,
    ...getSampleByIdentifier,
    ...getSampleByExternalReference,
    ...postResearchOrder,
    ...postResearchParticipant,
    ...getRuns,
    ...getRunById,
    ...getPlates,
    ...getExperiments,
    ...getExperimentById,
    ...getPlateById,
    ...getWellsByPlateId,
    ...patchPlateById,
    ...postRun,
    ...putUpdateRun,
    ...getSamplesheet,
    ...getPlatePreparationCandidates,
    ...postUploadSamplesheet,
    ...postValidateSamplesheet,
    ...postRunPreparation,
    ...postPlateToExperiment,
    ...postPlate,
    ...deletePlateFromExperiment,
    ...getVoucherCodeByKitId,
    ...getResearchProjectParticipants,
    ...getResearchProjectKits,
    ...getSampleFile,
    ...getSampleFiles,
    ...postSampleFile,
    ...deleteSampleFile,
    ...getSampleAliquots,
    ...postGenerateSampleAliquots,
    ...getBrands,
    ...getReports,
    ...getReportById,
    ...getReportsByReferralIds,
    ...updateReport,
    ...getReferralByKitId,
    ...getReferralsByPatientName,
    ...getReferralsByIds,
    ...getReferralsByPractitionerEmail,
    ...cancelReferralById,
    ...sendReferralNotificationById,
    ...replaceSample,
    ...getPatientById
  },
  actions
})
